@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: 'Montserrat', sans-serif;
}

 @media screen and (max-width: 767px){
  #CybotCookiebotDialog{
      transform: translate(-50%, 10%) !important;
      max-height: 45% !important;
  }
  #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
    display: none;
}
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}
 } 
